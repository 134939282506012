<script>
 import { onMount} from "svelte";

  import Graph from '$lib/graph.svelte'
  import interaction_store, { add_interaction } from '$lib/interaction_store.js';
  import Dropdown from "$lib/dropdown_old.svelte";
  import Instructor from '$lib/main_page_components/instructor.svelte'
  import Parent from '$lib/main_page_components/parent.svelte'
  import Student from '$lib/main_page_components/student.svelte'
  import Icon from '@iconify/svelte';
  import Banner from '$lib/main_page_components/banner.svelte';
  import BannerLeft from '$lib/main_page_components/banner_left.svelte';
  import HowItWorksCard from "$lib/main_page_components/how_it_works_card.svelte";
  import Card from "$lib/main_page_components/card.svelte";
  import Demo from "$lib/main_page_components/demo.svelte";
  import DemoTeacher from "$lib/main_page_components/demo_teacher.svelte";
  import { fade } from 'svelte/transition';
  import Expandable from '$lib/expandable_pill.svelte'
 import Schedule from "$lib/main_page_components/schedule.svelte"

  import {base} from "$app/paths";
  import PlanCard from "$lib/main_page_components/plan_card.svelte";


  $: display_schedule = false


  // Reusable action to fade elements in when they enter the viewport
  function fade_in_on_scroll(node) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Directly apply the fade transition here or add a class to trigger CSS animations
          node.style.opacity = 1;
          node.style.transform = 'none';
          observer.unobserve(node); // Optional: Stop observing after the element is visible
        }
      });
    }, { threshold: [0.1] });

    observer.observe(node);

    // Initial style to start from (e.g., faded out and slightly translated)
    node.style.opacity = 0;
    node.style.transform = 'translateY(100px)';
    node.style.transition = 'opacity 1s ease-out, transform 1s ease-out';

    return {
      destroy() {
        observer.unobserve(node);
      }
    };
  }
  
  let selected_demo = '';
  $: which_fuck = ''
</script>
<style>
  p + h3{
    margin-top: 60px;
  }

  a{
    font-size: 2.5em ;
  }
  .sex-align{
    display: flex;
    flex-direction: row;
  }
    .icon-container {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    margin-bottom: 8px; /* Spacing between rows */
  }

  .icon-text {
    margin-left: 8px; /* Spacing between icon and text */
  }
  .demo-group{
    margin-left: 69px;
  }
  .icon {
    vertical-align:middle;
    horiz-align: center;
  }
  .arrow-cont{
    margin-left: 13px;
  }

  .icon:not([icon*="arrow-up-solid"]) {
    padding: 5px; /* Padding to create space for the circle */
    border-radius: 50%; /* Circular shape */
    background-color: #f0f0f0; /* Background color for the circle */
  }


  .icon-circle {
    display: inline-flex; /* For proper alignment and circle shaping */
    justify-content: center;
    align-items: center;

    border-radius: 50%; /* Circular shape */
    padding: 5px; /* Space for circle */
    background-color: transparent; /*#f0f0f0; /* Background color for the circle */
  }


  @keyframes -global-slide-top {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100px);
    }
  }
  .title{
    font-size: 69px;
    line-height: 1.2em;
    font-weight: 600;
  }
  .subtitle{
    font-size: 34px;
    line-height: 1.2em;
    font-weight: 600;
  }
  .outer-hero{
    display:grid;
    grid-template-columns: auto auto;
    grid-gap: 10px
  }
  .half-size{
    max-width: 100%;
    height: auto;
  }
  .img-styling{

    border-radius: 17px;
    height: auto;
    width: 100%;
  }

  .hero-section{

  display: flex;
  gap: 3vw;
  align-items: center; 
  justify-content: center; 
  height: 100vh;
  flex-direction: row; 
  width: 80vw; /* Adjust width to account for the margins */
  margin-left: 10vw;
  margin-right: 10vw;    

  }

  .hero-text{
    font-size: 2.5vh;
  
    width: 100vw;
  }

  .filled-button{
  padding: 10px 20px; 
  border-radius: 20px; 
  border: 2px solid #605DF9;
  background-color: #605DF9; 
  color: white; 
  font-size: 16px; 
  text-transform: uppercase; 
  cursor: pointer; 
  transition: all 0.3s ease; 
  margin:5px;
 }

 .banner-container{
  display: flex;
  flex-direction: column; 
  gap: 15vh; 

 }

 .title-section-container{
  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 3vw;
  margin: 10vw 20vw;
  width: 80%; 
}

.container{

  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 3vw;
  margin: 10vw 20vw;
  width: 80%; 

}

 .title-section {
  color: #f0f0f0;
  font-size: 5vh;
 text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
 }
  
 .how-it-works{
  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 3vw;
  margin: 10vh 20vw; 
  width: 80%; 


 }
 .what-we-do{

  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 5vw;
  margin: 10vh 20vw; 
  width: 80%;
  flex-wrap: wrap;


 }

 .centered-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Ensures text within children is also centered, if needed */
 }

 .cta{
  background-image: linear-gradient(to bottom, #347fef, #000000);
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10vw;
  gap: 20vw;
  margin-bottom: 0;
  padding-bottom: 0;
 }

 @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Apply the animation to your element */
.fade-in {
    opacity: 0; /* Start from invisible */
    animation: fadeIn 1s ease-in forwards; /* Apply the fadeIn animation */
}


@media (max-width: 600px) {
  .hero-section {
    flex-direction: column;
   padding: 30px;
    gap: 5vw;
  }
  .image-container {
    order: -1; 
  }

  .title {
    font-size: 6vw;
  }

  .subtitle, .hero-text {
    font-size: 4vw;
    text-align: center;
  }

  .container, .title-section-container, .how-it-works, .what-we-do {
    margin: 5vw;
    gap: 5vw;
    flex-direction: column;
  }

  .banner-container, .cta {
    flex-direction: column;
    gap: 20vw;
    margin: 0 0 20vh 0;
  }

  img.img-styling {
    width: 80vw; /* Make images smaller and ensure they fit within the screen */
  }

  .filled-button {
    padding: 5vw 10vw; /* Increase button size for easier tapping */
    text-align: center;
    margin: 3vh 26vw  ;
  }

  .title-section{
    margin: 0 0 10vh 50vw;
    font-size: 8vw;
    width: 100%;
  }

  .how-it-works, .demo{
    margin: 0 0 0 14vh;

  }

  .card{
    width: 100%;
    margin: 20vw;
  }

  .container{
    margin:  10vh 30vw;

  }

 .demo-button{
  padding: 20px;
 }

 .cta{
  width: 100vw;
  margin-bottom: 0;
  padding-bottom: 0;
 }

}
</style>

<!--
<h1>Evalugrade</h1>
-->

<div class="hero-section fade-in">
  <div class="hero-text" >
    <h1>Get <strong style="color: #91248C;">instant feedback</strong> on your most <strong style="color: #605DF9;">challenging problems</strong>  </h1>
    <p>We evaluate and grade math from handwriting.</p>
    <a href={`${import.meta.env.VITE_API_URL}/views/create`}>
    <button class="filled-button">Get Started</button>
    </a>
  </div>
  <div class="image-container">
  
    <img class="img-styling" src={`${base}/images/2.png`} alt="Unlocking knowledge below a tree">
  </div>
</div>

<div  class="banner-container "  >

 <div use:fade_in_on_scroll >
  <Banner  smallText="We offer effective feedback." bigText="Instantly"  />

 </div>

 <div use:fade_in_on_scroll >
  
  <BannerLeft/>

 </div>

 <div use:fade_in_on_scroll >
  
  <Banner smallText="Accuracy ensured" bigText="Guaranteed" image={`${base}/images/11.png`} />


 </div>

  

</div>

<div use:fade_in_on_scroll  class="title-section-container" >

<h1 class="title-section"> How it works</h1>

</div>


<div class="how-it-works"  >

  <div use:fade_in_on_scroll>
    <HowItWorksCard icon="fa-solid:camera" text='Upload your handwritten work' detail_text='We guess what you meant'/>
  </div>
  <div use:fade_in_on_scroll>
    <HowItWorksCard text="Correct our interpretation of your work" detail_text="We aren't always correct 😢" icon="fluent:math-formula-32-filled"/>
  </div>

  <div use:fade_in_on_scroll>
    <HowItWorksCard text="Receive effective feedback" detail_text="We use real math to check your work (not AI garbage)." icon="fa-solid:brain" />
  </div>
</div>

<div use:fade_in_on_scroll  class="title-section-container">

  <h1 class="title-section"> What do we do?</h1>
  
</div>

  <div use:fade_in_on_scroll  class="what-we-do" >
    <Card text="Algebra" backgroundGradient='linear-gradient(to bottom, #91248C, #000000)'
          image={`${base}/images/algebras.png`} items={['Linear', 'Systems of Equations']}/>
    <Card text="Calculus"  image={`${base}/images/calculus.png`}
          items={['Derivatives', 'Trigonometry', 'Integration', 'Substitution by Parts' ]}/>

  </div>

<div use:fade_in_on_scroll  class="demo container">

  <p>For a more detailed preview, <br>
    please <strong> tell us about yourself: </strong></p>
  </div>

  <div use:fade_in_on_scroll  class="container" >

    <div class="centered-content" on:click={() => selected_demo = 'student'}>
      <img src={`${base}/images/student.png`} alt="teacher" width="100vw" style="z-index: 1;"/>
      <div class="demo-button" style="background-color: {selected_demo === 'student' ? '#521BC3' : 'white'}; padding: 3vh 4vw; text-align:center; margin-top: -2vh;  border-radius: 10px;">
        <p style="color: black; font-weight: bold; color: {selected_demo === 'student' ? 'white' : '#521BC3'};">I'm a Student</p>
      </div>
    </div>
    <div class="centered-content" on:click={() => selected_demo = 'teacher'}>
      <img src={`${base}/images/teacher.png`} alt="teacher" width="100vw" style="z-index: 1;"/>
      <div class="demo-button" style="background-color: {selected_demo === 'teacher' ? '#9136C8' : 'white'}; padding: 3vh 4vw; text-align:center; margin-top: -2vh;  border-radius: 10px;">
        <p style="color: black; font-weight: bold; color: {selected_demo === 'teacher' ? 'white' : '#9136C8'};">I'm a Teacher</p>
      </div>
    </div>

</div>
<div  use:fade_in_on_scroll class="demo container">
{#if selected_demo === 'student'}
  <div in:fade={{ duration: 300 }}>
    <Demo imageStep={`${base}/images/16.png`} image={`${base}/images/6.png`}/>
  </div>
{:else if selected_demo === 'teacher'}
<div in:fade={{ duration: 300 }}>
  <DemoTeacher image={`${base}/images/1.png`}/>
</div>
{/if}
  

</div>
{#if selected_demo }
<div use:fade_in_on_scroll  class="title-section-container">

  <h1 class="title-section"> Plan</h1>
  
  </div>

<div use:fade_in_on_scroll  class="container"  >

{#if selected_demo === 'student'}

  <PlanCard title="Student"
            price="$30 / month"
            image={`${base}/images/6.png`}
            followup_link={`${import.meta.env.VITE_API_URL}/views/create`}
            items={["Algebra",'Trigonometry', 'Derivatives', 'Integration', 'Substitution', 'By Parts']}

  />
{:else if selected_demo === 'teacher'}
  <PlanCard title="Instructor"
            background_gradient='linear-gradient(to bottom, #91248C, #000000)'
            image={`${base}/images/2.png`} on:click={ev=>display_schedule=!display_schedule}
            items={["Algebra",'Trigonometry', 'Derivatives', 'Integration', 'Substitution', 'By Parts']}

  />
  {#if display_schedule}
    <Schedule>

    </Schedule>
  {/if}
{/if}

</div>

<!--
<div use:fade_in_on_scroll  class="title-section-container">

  <h1 class="title-section"  > FAQs</h1>
  
</div>
<div use:fade_in_on_scroll  class="container ">
  <div style="display: flex;,">
    <div class="faq-dropdown" >

    </div>
    <img src={`${base}/images/9.png`} alt="frequent asked questions" width="75%">

  </div>

</div>
-->


<div use:fade_in_on_scroll class="cta">
  <div class="text">
    <h1 style="font-size: 44px;" class="text-cta">
      Are you ready to revolutionize your <strong>learning experience</strong> ?  
    </h1>
    <h3>Join us now to transform your math journey!</h3>
    <button class="filled-button"> Get Started</button>
  </div>
  <div class="cta-img">
    <img src={`${base}/images/3.png`} alt="cta" width="100%">
  </div>
</div>
  <!--
  <div use:fade_in_on_scroll class="cta">
    <Expandable label="Demo">
      {#if selected_demo=== "student"}
        <Student>

        </Student>
      {:else}
        <Instructor>

        </Instructor>
      {/if}
    </Expandable>
  </div>
  -->
{:else}
  <div>

  </div>
{/if}

<!-- <div class="demo-group">

  <div class="icon-container">
    <div class="icon-circle">
    <Icon class="icon" icon="radix-icons:camera"  width={34} height={34}/>
    </div>
    <p class="icon-text">Upload an image of your handwritten work</p>
  </div>
  <div class="icon-container  arrow-cont">
    <Icon class="icon" icon="teenyicons:arrow-up-solid" rotate={2}/>
  </div>
  <div class="icon-container">
    <div class="icon-circle">
    <Icon class="icon" icon="fluent:math-formula-32-filled" width={34} height={34} />
    </div>
    <p class="icon-text">Correct our interpretation of your work</p>
  </div>
  <div class="icon-container arrow-cont">
    <Icon class="icon" icon="teenyicons:arrow-up-solid" rotate={2} />
  </div>
  <div class="icon-container">
    <div class="icon-circle">
    <Icon class="icon" icon="fluent:brain-circuit-20-regular"  width={34} height={34}/>
    </div>
    <p class="icon-text">Receive feedback</p>
  </div>
</div>


<p>Our system is capable of evaluating based on a rubric or nothing at all!</p>
<h2>INJESTIV's Evalugrade &trade; &#8212; Introducing Autonomous Grading</h2>
<p>INJESTIV's Evalugrade eliminates manual evaluation, interpreting the handwritten
work of you and your students.</p>
-->

<!-- <p>
  We evaluate and grade math from handwriting. Autonomously. We grade for you, directly interpreting the handwritten work of you and your students</p> -->

<!-- <p>
  Simply capture an image of a handwritten problem and let Evalugrade do the rest!
</p> -->



<!-- <p>For a more detailed preview, please tell us about yourself: </p>
<div class="sex-align"><p>I am a </p>
  <Dropdown options={["student",  "instructor", ]} on:select={(ev)=>{which_fuck=ev.detail.selected}}>
  </Dropdown>
</div> -->
<!-- {#if which_fuck === 'student'}
   <Student/>
  {:else if which_fuck  === 'instructor'}
  <Instructor></Instructor>
  {:else if which_fuck === "homeschooler"}
   <Parent />

{/if}  -->